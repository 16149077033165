import { Box, Button, Input, Select, Td, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getBetHistoryAPI, searchUserAPI } from "../../service/AuthService";
import AsyncSelect from "react-select/async";
import moment from "moment";

export default function BetHistory() {
  const [from, setFrom] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [to, setTo] = React.useState(moment().format("YYYY-MM-DD"));
  const [sport, setSport] = React.useState("CRICKET");
  const [matched, setMatched] = React.useState("MATCHED");
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const getBetHistory = async () => {
    let payload = {
      from: new Date(from),
      matched: matched == "MATCHED" ? true : false,
      page,
      size,
      sport,
      to: new Date(to),
      userName: userName?.value,
    };
    const {
      response: { content },
    } = await getBetHistoryAPI(payload);
    let data_ = content.map((item, index) => ({
      ...item,

      bet_type: item.back_rate || item.back_run ? "Back" : "Lay",
      rate: item.back_run ? item.back_run : item.lay_run,
      "p/l": item.profit ? item.profit : item.loss,
    }));
    setData(data_);
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  useEffect(() => {
    getBetHistory();
  }, [size]);
  return (
    <Box className="col-md-12 report-main-content m-t-5">
      <Box
        bg="#4F4F4F"
        p="2"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        fontWeight="bold"
        color="white"
      >
        <Text fontSize="xl">Bet History</Text>
      </Box>
      <Box mt="5" display="flex" p="2" w="60%">
        <Select
          onChange={(e) => {
            setSport(e.target.value);
          }}
          mx="1"
          value={sport}
        >
          <option value="ALL">Sports Type</option>
          <option value="CRICKET">Cricket</option>
          <option value="SOCCER">Football</option>
          <option value="TENNIS">Tennis</option>
          <option value="CASINO">Casino</option>
        </Select>
        <Select
          onChange={(e) => {
            setMatched(e.target.value);
          }}
          mx="1"
          value={matched}
        >
          <option value="ALL">Bet Status</option>
          <option value="MATCHED">Matched</option>
          <option value="DELETED">Deleted</option>
        </Select>
        <Input
          onChange={(e) => {
            setFrom(e.target.value);
          }}
          value={from}
          mx="1"
          type="date"
        />
        <Input
          onChange={(e) => {
            setTo(e.target.value);
          }}
          value={to}
          mx="1"
          type="date"
        />

        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={searchClient}
          onChange={(e) => setUserName(e)}
          isClearable={true}
          value={userName}
          styles={{
            control: (base) => ({
              ...base,
              height: "35px",
              minHeight: "35px",
              width: "200px",
            }),
          }}
        />
        <button
          style={{
            backgroundColor: "#0079FB",
            color: "white",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "5px",
            marginLeft: "10px",
          }}
          onClick={() => getBetHistory()}
        >
          Submit
        </button>
      </Box>
      <div className="table-responsive data-table" id="tbldata">
        <div
          id="table-active_bets_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <div className="dataTables_length" id="table-active_bets_length">
            <label>
              Show{" "}
              <select
                name="table-active_bets_length"
                aria-controls="table-active_bets"
                className
                onChange={(e) => setSize(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>{" "}
              entries
            </label>
          </div>
          {/*  <div id="table-active_bets_filter" className="dataTables_filter">
            <label>
              Search:
              <input
                onFocus={() => search_.length > 2 && setSize(50)}
              
                type="search"
                className="form-control"
                placeholder
                aria-controls="table-active_bets"
                onChange={search}
              />
            </label>
          </div> */}
          <table
            id="table-active_bets"
            className="table table-bordered datatable dataTable no-footer"
            role="grid"
            aria-describedby="table-active_bets_info"
          >
            <thead>
              <tr role="row">
                <th>S.No.</th>

                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Event Name: activate to sort column ascending"
                  style={{ width: "156px" }}
                >
                  Event Name
                </th>

                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Runner Name: activate to sort column ascending"
                  style={{ width: "175px" }}
                >
                  Runner Name
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Bet Type: activate to sort column ascending"
                  style={{ width: "115px" }}
                >
                  Bet Type
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="User Rate: activate to sort column ascending"
                  style={{ width: "128px" }}
                >
                  User Rate
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Amount: activate to sort column ascending"
                  style={{ width: "106px" }}
                >
                  Profit Loss
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Amount: activate to sort column ascending"
                  style={{ width: "106px" }}
                >
                  Amount
                </th>

                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Place Date: activate to sort column ascending"
                  style={{ width: "141px" }}
                >
                  Place Date
                </th>
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="table-active_bets"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Match Date: activate to sort column ascending"
                  style={{ width: "150px" }}
                >
                  Match Date
                </th>
              </tr>
            </thead>
            <tbody id="bet_tbody">
              {data.length == 0 && (
                <tr className="odd">
                  <td valign="top" colSpan={10} className="dataTables_empty">
                    No data available in table
                  </td>
                </tr>
              )}
              {data.map((item, index) => {
                return (
                  <tr
                    style={{
                      backgroundColor: `${
                        item.bet_type == "Back"
                          ? "rgba(114, 187, 239, 1)"
                          : "rgba(249, 148, 186, 1)"
                      } `,
                    }}
                    role="row"
                    className="odd"
                    key={index}
                  >
                    <td width="60px">{index + 1}</td>

                    <td>{item.event_name}</td>

                    <td>{item.runner_name}</td>
                    <td>{item.bet_type}</td>
                    <td>{item.back_run ? item.back_run : item.lay_run}</td>
                    <td>{item["p/l"]}</td>
                    <td>{item.amount}</td>

                    <td>
                      {moment(item.created_date + "Z")
                        .utc()
                        .local()
                        .format("DD/MM/YYYY hh:mm")}
                    </td>
                    <td>
                      {moment(item?.created_date + "Z")
                        .utc()
                        .format("DD-MM-YYYY HH:mm")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <div
                      className="dataTables_info"
                      id="table-active_bets_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 0 to 0 of 0 entries
                    </div> */}
          {/* <div
            className="dataTables_paginate paging_simple_numbers"
            id="table-active_bets_paginate"
          >
            <a
              className="previous  button btn btn-diamond"
              aria-controls="table-active_bets"
              data-dt-idx={0}
              tabIndex={-1}
              id="table-active_bets_previous"
              onClick={prevPage}
            >
              Previous
            </a>
            <span />
            <a
              className="next  button btn btn-diamond"
              aria-controls="table-active_bets"
              data-dt-idx={1}
              tabIndex={-1}
              id="table-active_bets_next"
              onClick={nextPage}
            >
              Next
            </a>
          </div> */}
        </div>
      </div>
      {/*  <CustomTable
        columns={[
          {
            name: "Event Name",
            id: "event_name",
            element: (item) => (
              <Td
                borderLeft="solid 4px"
                borderLeftColor={
                  item.bet_type == "Back" ? "#72BBEF" : "#F993BA"
                }
              >
                <Text overflow="hidden" textOverflow="ellipsis">
                  {item.event_name}
                </Text>
              </Td>
            ),
          },
          {
            name: "Nation",
            id: "runner_name",
          },
          {
            name: "Bet Type",
            id: "bet_type",
          },
          {
            name: "User Rate",
            id: "rate",
          },
          {
            name: "Amount",
            id: "amount",
          },
          {
            name: "Profit/Loss",
            id: "p/l",
          },
          {
            name: "Place Date",
            id: "created_date",
            element: (item) => (
              <Td>
                <Text overflow="hidden" textOverflow="ellipsis">
                  {moment(item.created_date + "Z").format("DD-MM-YYYY HH:mm")}
                </Text>
              </Td>
            ),
          },
          {
            name: "Match Date",
            id: "created_date",
            element: (item) => (
              <Td>
                <Text overflow="hidden" textOverflow="ellipsis">
                  {moment(item.created_date + "Z").format("DD-MM-YYYY HH:mm")}
                </Text>
              </Td>
            ),
          },
        ]}
        rows={data}
        currentPage_={page}
      /> */}
    </Box>
  );
}
