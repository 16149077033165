import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export default function CustomModal({ isOpen, children, header }) {
  const ui = useSelector((state) => state.account.ui);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (ui.action == "logout") {
      dispatch({
        type: "accountData/openPopUp",
        payload: {
          modalOpen: false,
        },
      });
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      window.localStorage.clear();
      window.location.href = "/";
    }
    onClose();
  };
  const onClose = () => {
    dispatch({
      type: "accountData/openPopUp",
      payload: {
        modalOpen: false,
      },
    });
  };
  return (
    <Modal isCentered size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg="#2B2B2B">{header}</ModalHeader>
        <ModalBody
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          alignItems={"center"}
        >
          <Text color="green" fontSize="3xl">
            {ui?.modalBody}
          </Text>
          <Text fontSize="xl">{ui?.modalContent}</Text>
          <Button
            color="white"
            w="30%"
            bg="#2B2B2B"
            display={"flex"}
            onClick={handleClick}
          >
            <ArrowBackIcon />
            <Text>Back</Text>
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
