import { Alert, Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { loginAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { generateHash } from "../service";
export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const login = async () => {
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    const { response, message, code } = await loginAPI(
      userName,
      Encrypt(password)
    );
    if (userName === "" || password === "") {
      toast.error("Please enter username and password");
      return;
    }
    if (code == 200) {
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });

      window.location.href = "/admin/market-analysis";
    } else {
      toast.error(response);
    }
  };
  return (
    <Flex direction="column" className="login_container">
      <Box mb="20px" className="login_logo">
        <img src={require("../assets/img/logo-b.png")} />
      </Box>
      <Flex direction="column" className="login_box">
        <h2>Sign In</h2>
        <Input
          onKeyDown={handleKeyDown}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="Username"
        />
        <Input
          onKeyDown={handleKeyDown}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
        />
        <Button onKeyDown={handleKeyDown} onClick={login}>
          <Text>Login</Text>
        </Button>
      </Flex>
    </Flex>
  );
}
