import { Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import CustomInput from "../../../components/UI/CustomInput";

export default function Password({ payload, setPayload }) {
  return (
    <Grid gap={1} fontSize="sm" templateColumns="repeat(2,1fr)">
      <GridItem>
        <Text>New Password</Text>
      </GridItem>
      <GridItem>
        <CustomInput
          type="password"
          onChange={(e) => {
            setPayload({
              ...payload,
              newPassword: e.target.value,
            });
          }}
        />
      </GridItem>
      <GridItem>
        <Text>Confirm Password</Text>
      </GridItem>
      <GridItem>
        <CustomInput
          type="password"
          onChange={(e) => {
            setPayload({
              ...payload,
              confirmPassword: e.target.value,
            });
          }}
        />
      </GridItem>
      <GridItem>
        <Text>Transaction Password</Text>
      </GridItem>
      <GridItem>
        <CustomInput
          type="password"
          onChange={(e) => {
            setPayload({
              ...payload,
              transactionPassword: e.target.value,
            });
          }}
        />
      </GridItem>
    </Grid>
  );
}
