
import store from "../redux/store";
import { BASE_URL } from "../utils/constants";
import { AXIOS_INSTANCE } from ".";

export const getClientAPI = async (search, page, size, id) => {
  try {
    const { data } = await AXIOS_INSTANCE .get(`${BASE_URL}/admin/list-children`, {
      params: {
        page,
        size,
        search,
        userName: id ? id : null,
      },
     
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const searchChildrenAPI = async (search) => {
  try {
    const { data } = await AXIOS_INSTANCE .get(
      `${BASE_URL}/admin/search-children?search=${search}`,
     
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const addNewClientAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE .post(
      `${BASE_URL}/auth/signup`,
      {
        ...payload,
      },
     
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getGlobalSettingsAPI = async (childUserId) => {
  try {
    const { data } = await AXIOS_INSTANCE .get(`${BASE_URL}/admin/global-settings`, {
      params: {
        childUserId: childUserId,
      },
      
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserDataAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE .get(`${BASE_URL}/admin/user-info`, );
    return data;
  } catch (error) {
    return error;
  }
};

export const ChangeClientPasswordAPI = async (payload, childId) => {
  try {
    const { data } = await AXIOS_INSTANCE .put(
      `${BASE_URL}/admin/children/${childId}/password`,
      {
        ...payload,
      },
     
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const ChangeClientStatusAPI = async (
  childId,
  isActive,
  isBetLocked,
  transactionPassword
) => {
  try {
    const { data } = await AXIOS_INSTANCE .put(
      `${BASE_URL}/admin/children/${childId}/status`,
      {},
      {
        params: {
          isActive,
          isBetLocked,
          transactionPassword,
        },
        
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const ChangeClientLimitAPI = async (payload, childId) => {
  try {
    const { data } = await AXIOS_INSTANCE .put(
      `${BASE_URL}/admin/children/${childId}/limit`,
      {
        ...payload,
      },
     
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const ChangeClientGlobalSettingAPI = async (payload, childId) => {
  try {
    const { data } = await AXIOS_INSTANCE .put(
      `${BASE_URL}/admin/children/${childId}/global-settings`,
      {
        ...payload,
      },
     
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const ClientWithdrawAndDepositAPI = async (childId, payload) => {
  try {
    const { data } = await AXIOS_INSTANCE .put(
      `${BASE_URL}/admin/children/${childId}/deposit-withdraw`,
      {
        ...payload,
      },
      
    );
    return data;
  } catch (error) {
    return error;
  }
};
