import React from "react";
import "./modal.css";
import { getBetsFromAccountLogs } from "../../../service/AuthService";
import { useEffect } from "react";
import moment from "moment";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
export default function ClientLegder({ accountLogId }) {
  const [header_, setHeader_] = React.useState("Client Ledger");
  const [data, setData] = React.useState([]);
  const getData = async () => {
    const { response } = await getBetsFromAccountLogs(accountLogId);
    let response_ = response;
    let betResponseDTOList = response_.betResponseDTOList;
    let betResponseDTOList_ = [];
    betResponseDTOList.forEach((item) => {
      if (item.back_rate && item.event_type == "Casino") {
        item.type = "back";
        item.rate = item.back_rate;
      } else if (item.lay_rate && item.event_type == "Casino") {
        item.type = "lay";
        item.rate = item.lay_rate;
      }
      if (
        item.back_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "back";
        item.rate = item.back_run;
      } else if (
        item.lay_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "lay";
        item.rate = item.lay_run;
      }
      betResponseDTOList_.push(item);
    });
    response_.betResponseDTOList = betResponseDTOList_;
    let header = `Client Ledger (Total Win Loss: ${response.totalWin}) (Total Soda: ${response.betResponseDTOList.length})`;
    setHeader_(header);
    setData(response_);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div class="modal-header">
        <h4 class="modal-title">{header_}</h4>
      </div>
      <div className="modal-body">
        <div className="table-responsive">
          <table className="table table-striped jambo_table">
            <thead>
              <tr>
                <td>Uplevel</td>
                <td>Uername</td>
                <td>Nation</td>
                <td>UserRate</td>
                <td>Amount</td>
                <td>Win/Loss</td>
                <td>PlaceDate</td>
                <td>MatchDate</td>
                <td>Ip</td>
                <td>BrowsersDetails</td>
              </tr>
            </thead>
            <tbody id="ac_more_details_tbody">
              {data?.betResponseDTOList?.map((item, index) => (
                <tr className={`${item.type}-f`}>
                  <td className="text-left">{item.upline}</td>
                  <td className="text-left">{item.userName}</td>
                  <td className="text-left">{item.runner_name}</td>
                  <td className="text-left">{item.rate}</td>
                  <td className="text-left">{item.amount}</td>
                  <td className="text-right">
                    <span
                      style={{
                        color: item.loss ? "red" : "green",
                      }}
                      id="style-JaY18"
                      className="style-JaY18"
                    >
                      {item.loss ? "-" + item.loss : item.profit}
                    </span>
                  </td>
                  <td className="text-left">
                    {moment(item.created_date + "Z").format("DD/MM/YYYY hh:mm")}
                  </td>
                  <td className="text-left">
                    {moment(item.created_date + "Z").format("DD/MM/YYYY hh:mm")}
                  </td>
                  <td className="text-left">{item.ipAddress}</td>
                  <td>
                    <Popover preventOverflow={true}>
                      <PopoverTrigger>
                        <Text _hover={{ cursor: "pointer" }} color="blue.500">
                          Show
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent>{item?.browserDetails}</PopoverContent>
                    </Popover>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
