import React, { useEffect } from "react";
import CustomDataModal from "../../../components/UI/CustomDataModal";
import CustomModal from "../../../components/UI/CustomModal";
import CustomTable from "../../../components/UI/Table";
import {
  getAdminBookMakerCricketAPI,
  getAdminUserBookAPI,
  getAdminUserBookCricketAPI,
} from "../../../service/cricket";
import {
  getAdminBookMakerSoccerAPI,
  getAdminBookMakerTennisAPI,
  getAdminUserBookSoccerAPI,
  getAdminUserBookTennisAPI,
} from "../../../service/soccer&tennis";

export default function BookMakersBook({
  eventId,
  isOpen,
  type,
  onClose,
  gameType,
}) {
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const getBookMakerBook = async () => {
    let data;
    if (type == "user") {
      if (gameType === "cricket") {
        const { response } = await getAdminUserBookCricketAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "soccer") {
        const { response } = await getAdminUserBookSoccerAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "tennis") {
        const { response } = await getAdminUserBookTennisAPI(eventId);
        if (response) {
          data = response;
        }
      }
    } else if (type == "book") {
      if (gameType === "cricket") {
        const { response } = await getAdminBookMakerCricketAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "soccer") {
        const { response } = await getAdminBookMakerSoccerAPI(eventId);
        if (response) {
          data = response;
        }
      } else if (gameType === "tennis") {
        const { response } = await getAdminBookMakerTennisAPI(eventId);
        if (response) {
          data = response;
        }
      }
    }
    if (data) {
      let rows_ = [];
      let columns_ = [];
      data.map((item) => {
        let row = {};
        row.userName = item?.userName;
        item?.runnerBookResponseDTOS?.map((match) => {
          row[match?.runnerName] = match?.pandL;
        });
        rows_.push(row);
      });
      if (rows_.length > 0) {
        columns_.push({ name: "User Name", id: "userName" });
        Object.keys(rows_[0]).map((key) => {
          if (key !== "userName") {
            columns_.push({ name: key, id: key });
          }
        });
        setColumns(columns_);
        setRows(rows_);
      }
    }
  };

  useEffect(() => {
    getBookMakerBook();
  }, [eventId, gameType]);
  return (
    <CustomDataModal
      header={type == "book" ? "Bookmaker Book" : "User Book"}
      onClose={onClose}
      isOpen={isOpen}
    >
      <CustomTable columns={columns} rows={rows} />
    </CustomDataModal>
  );
}
