import React from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
export default function PublicOutlet(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userData = useSelector((state) => state.account.userData);
  if (userData.token) {
    return <Navigate to="/admin/manage-client/list" />;
  }
  return (
    <>
      <Box minH="100vh" className="login_bg">
        {props.children}
      </Box>
    </>
  );
}
