import { AXIOS_INSTANCE } from "..";
import { CASINO_SERVICE_URL, getToken } from "../../utils/constants";

export const getCasinoReportHistory = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/casino/casino-report-history`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getIndividualResultAPI = async (marketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/casino/individaul-result?marketId=${marketId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
